<template>
	<div class="container-fluid">
        <div class="row">
            <div class="col-12">
				<div class="box">
					<div class="row">
						<div class="col-3">
							<label>{{ $t('global.type_analyse') }}</label>
			                <e-select
								v-model="acte_type_selected"
								id="actes_types"
								track-by="name"
								label="name"
								:placeholder="$t('task_planning.select_actes_types')"
								:options="actes_types"
								:searchable="true"
								:show-labels="false"
								:allow-empty="false"
								:group-select="false"
							>
								<template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
								<template slot="noOptions">{{ $t('global.list_empty') }}</template>
							</e-select>
						</div>
						<div class="col-9">
				            <DateRange
				                :start.sync="start_date"
				                :end.sync="end_date"
				                @submit="loadResultat"
				            />
						</div>
					</div>

		            <CustomTable
		                id_table="horse_actes_result_global"
		                ref="table"
		                :items="resultats"
		                :busy.sync="table_busy"
		                primaryKey="result_id"
		                :hrefsRoutes="config_table_hrefs"
		                :hide_if_empty="true"
		            />
				</div>
			</div>
		</div>
		<b-modal ref="modal" hide-footer>
			<template v-slot:modal-title>
				{{ $t("global.modifier") }}
            </template>

			<SanitaireResult :results="results_edit" :is_shutter='false' @submit="submitEdit"/>
		</b-modal>
	</div>
</template>

<script type="text/javascript">
	import ResultatAnalyse from "@/mixins/ResultatAnalyse.js"
	import Navigation from "@/mixins/Navigation.js"
	import Actes from "@/mixins/Actes.js"

	export default {
		name: 'ResultatSanitaire',
		mixins: [ResultatAnalyse, Navigation, Actes],
		data () {
			return {
				start_date: new Date(new Date().setMonth(new Date().getMonth() - 2)),
				end_date: new Date(new Date().setMonth(new Date().getMonth())),
				resultats: [],
				table_busy: false,
				acte_type_selected: null,
				actes_types: [],
				types_whitelist: ['TEST_NEW2_ARTRITE_VIRALE_QUINE_EVA_POUR_JUMENT', 'TEST_NEW2_ARTRITE_VIRALE_QUINE_EVA_POUR_TALON', 'TEST_NEW2_MTRITE_CONTAGIEUSE_QUINE_MEC_POUR_TALON', 'TEST_NEW2_MTRITE_CONTAGIEUSE_QUINE_MEC_POUR_JUMENT', 'TEST_NEW2_ANMIE_INFCTIEUSE_DES_EQUIDS_AIE'],
                config_table_hrefs: {
                    'horse.horse_nom': {
                        routeUniqueName: 'horseFiche',
                        routeName: 'horseFiche',
                        params: {
                            horse_id: 'horse.horse_id'
                        }
                    }
				},
				events_tab: {
                    'TableAction::goToEditResult': this.openEditResult
                },
                results_edit: []
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
				let actes_types = await this.getActesTypes()
				actes_types = actes_types.filter(acte_type => acte_type.actestype_label !== "---" && this.types_whitelist.includes(acte_type.actestype_code))

				this.actes_types = actes_types.map(type => ({
					id: type.actestype_id,
					name: type.actestype_label
				}))
			},

			async loadResultat() {
				if(this.table_busy || !this.acte_type_selected) {
					return false
				}

				this.table_busy = true
				this.resultats = await this.getSanitaireResult(this.acte_type_selected.id, this.start_date.toDateInputValue(), this.end_date.toDateInputValue())
				this.table_busy = false
			},

			openEditResult(results) {
				this.results_edit = results
				this.$refs.modal.show()
			},

			async submitEdit() {
				this.$refs.modal.hide()
				this.$refs.table.unselectAll()
				this.loadResultat()
			}
		},

		components: {
			CustomTable : () => import('GroomyRoot/components/Table/CustomTable'),
	        DateRange: () => import('GroomyRoot/components/Inputs/DateRange'),
	        SanitaireResult: () => import('@/components/Sanitaire/Result'),
		}
	}

</script>